.carousel-container {
  width: 60vw;
  height: auto;
}

@media (max-width: 1300px) {
  .carousel-container {
    width: 100vw;
    height: auto;
  }
}

@media (max-width: 576px) {
  .carousel-container {
    margin: 15px 0px 15px 0px;
  }
  .carousel-container {
    text-align: center;
  }
}

.navbar-transparent {
  background-color: transparent !important;
}

.carousel-indicators > li {
  border-radius: 100% !important;
  width: 15px !important;
  height: 15px !important;
}

.carousel-width-height-100 {
  /* height: 100vh; */
  width: 100%;
}

.carousel-item {
  width: 100%;
  height: 100%;
}

.carousel-control-prev,
.carousel-control-next {
  /* width: auto !important; */
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.carousel-control-next {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 60px;
  height: 60px;
  background-color: black;
  background-size: 70% 70%;
}

.carousel-inner {
  width: 100%;
  height: 100%;
}

.carousel-backgound {
  background-color: #f3fdff;
}
