.carousel-item-container {
  width: 100%;
  height: 100%;
}

@-webkit-keyframes carousel-item-animation {
  from {
    opacity: 0;
    margin-left: 70%;
    width: 150%;
  }

  to {
    opacity: 1;
    margin-left: 5%;
    width: 100%;
  }
}

@keyframes carousel-item-animation {
  from {
    opacity: 0;
    margin-left: 70%;
    width: 150%;
  }

  to {
    opacity: 1;
    margin-left: 5%;
    width: 100%;
  }
}

.carousel-item-animation {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-name: carousel-item-animation;
  animation-name: carousel-item-animation;
}

@-webkit-keyframes carousel-background-animation {
  from {
    background-position: 0% 0%;
  }

  to {
    background-position: 70% 0%;
  }
}

@keyframes carousel-background-animation {
  from {
    background-position: 0% 0%;
  }

  to {
    background-position: 70% 0%;
  }
}

.carousel-background-animation {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-name: carousel-background-animation;
  animation-name: carousel-background-animation;
}

.carousel-read-more-button {
  padding: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  font-size: 0.9rem;
  background-color: transparent;
}

.carousel-read-more-button:hover {
  background-color: black;
  color: white;
}

@media (min-width: 1140px) {
  .carousel-item-left-conditional-right-align {
    text-align: right !important;
  }
}

@media (max-width: 450px) {
  .carousel-item-left-conditional-right-align {
    width: 100%;
    margin: 0 !important;
  }
  .carousel-image-container {
    width: 100% !important;
    text-align: center;
  }
  .carousel-image-container img {
    width: 100% !important;
    height: auto !important;
  }
  @-webkit-keyframes carousel-item-animation {
  }
  @keyframes carousel-item-animation {
  }
  @-webkit-keyframes carousel-background-animation {
    from {
      background-position: 0% 0%;
    }

    to {
      background-position: 50% 0%;
    }
  }
  @keyframes carousel-background-animation {
    from {
      background-position: 0% 0%;
    }

    to {
      background-position: 50% 0%;
    }
  }
}

.carousel-image-container {
  display: inline-block;
  width: 450px;
  height: auto;
}
