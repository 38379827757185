.footer-container {
  background-color: #393e54;
}

#footer-item {
  font-family: "Nanum Gothic" !important;
  font-size: 0.8rem;
}

.footer-text {
  font-size: 0.8rem;
  color: white;
  font-weight: 200;
}

.footer-vertical-divider {
  width: 1px;
  font-size: 1rem;
  height: 20px;
  margin: 3px;
  border-color: #d3d3d3;
  border-style: solid;
  border-width: 0px;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 2px;
}

#inline-list-style {
  list-style-type: circle;
}

.footer-form-icon {
  height: 2.4rem;
  vertical-align: top;
}

.footer-form-title {
  font-size: 2rem;
}

.footer-form-container input {
  background-color: transparent;
  border-radius: 0;
  color: white;
}

.footer-form-container input:focus {
  background-color: transparent;
  border-radius: 0;
  color: white;
}

.footer-form-container textarea {
  background-color: transparent;
  border-radius: 0;
  color: white;
}

.footer-form-container textarea:focus {
  background-color: transparent;
  border-radius: 0;
  color: white;
}

.form-group button {
  border-radius: 0;
  padding-left: 15px;
  padding-right: 15px;
}

.footer-image {
  width: 200px;
  height: auto;
}

.footer-fab-button {
  padding: 10px;
  font-size: 1rem;
  background-color: #d3d3d3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .footer-quick-counsel-form {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .footer-fab-responsive {
    display: none;
  }
}
.footer-fab-form-container {
  position: relative;
  bottom: 0;
}

.footer-fab-form-container label {
  color: white;
  font-size: 1.2rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.footer-fab-form-container .form-group {
  margin-bottom: 0px;
}
.footer-fab-responsive {
}

.footer-privacy-button {
  background-color: transparent;
  border-width: 0;
  color: white;
}

.fee-table th {
  min-width: 100px;
  vertical-align: middle !important;
}

.fee-table td {
  vertical-align: middle;
}
