.popup {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 20; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.popup img {
  max-width: 640px;
  width: 100%;
  height: auto;
}

.popup-bottom-container {
  background-color: white;
  height: 40px;
  max-width: 640px;
}

.popup-image-container {
  max-width: 640px;
}
