.fab-top-container {
  background-color: #333;
  color: white;
  font-size: 1.25rem;
  width: 180px;
  height: 45px;
  border-width: 0px;
}

.fab-image-container {
  width: 20%;
}

.fab-button-container {
  width: 180px;
  height: 45px;
  border-width: 0px;
}

.fab-button-qrcode-container {
  width: 180px;
  height: 135px;
  border-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab-button-image {
  width: auto;
  height: 24px;
}

.fab-button-title {
  font-size: 1rem;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  line-height: 45px;
}

@media (max-width: 768px) {
  .fab-reponsive-container {
    display: none;
  }
}

@media (min-width: 768px) {
  .mobile-fab-container {
    display: none;
  }
}

.mobile-fab-container {
  position: "absolute";
  bottom: 10px;
  right: 10px;
}

.mobile-fab-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: transparent;
  border-width: 0;
}

.mobile-fab-button > img {
  width: "90%";
  height: "auto";
}
