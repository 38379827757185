.icon-bar {
  border-radius: 0px;
  width: 100%;
  height: 6px;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: white;
}

.navbar-collapse {
  z-index: 999;
}

.navbar-button-container {
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-color: transparent;
  border-color: white;
  border-width: 3px;
}

.navbar-background-color {
  background-color: white !important;
}

.collapsed-aria {
  height: 90vh;
  background-color: rgba(51, 51, 51, 0.9);
}

.collapsed-aria li {
  vertical-align: middle;
  -ms-flex-pack: center;
  justify-content: center;
  height: 80px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: #dbdbdb;
  list-style: none;
}

.link {
  font-family: "Noto Sans KR" !important;
  font-size: 1.5em;
  color: #efefef !important;
}

.nav-item-hover:hover {
  opacity: 0.7;
  background-color: rgba(209, 209, 209, 0.3) !important;
}

.dropdown-item {
  font-size: 0.9rem;
}

@media screen and (max-width: 770px) {
  .nav-item > .active {
    display: block;
  }

  .nav-item > a {
    display: none;
  }
  .navbar-responsive {
    padding: 0px !important;
  }
}

#background-section {
  /* background-color: #efefef; */
  background-color: red;
  z-index: 0;
}

@media (min-width: 768px) {
  .navbar-button-container {
    display: none;
  }
}

.nav-item {
  color: #2c2c2c;
  font-size: 1rem;
}

.nav-item-active {
  color: #1c1c1c;
}

a:hover {
  color: #c1c1c1;
  text-decoration: none;
}

.nav-vertical-devider {
  width: 1px;
  height: 1.2rem;
  border-style: solid;
  border-color: #d8d9d8;
  border-width: 0px;
  border-left-width: 1px;
  vertical-align: middle;
}

.dropbtn {
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  left: 0px;

  background-color: black;
  min-width: 160px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: white;
  padding: 12px 16px;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: white;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.dropdown-content a {
  height: 50px;
  font-size: 0.8rem;
}

.navbar-login-button {
  font-weight: 600;
  font-size: 1rem !important;
}

.dropdown-menu {
  margin: 0px;
  border-radius: 0px !important;
  border-bottom-color: #3691ae;
  padding: 0px;
  min-width: 0;
}

.dropdown-item {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  height: 45px;
  border-color: rgba(0, 0, 0, 0.15);
  border-bottom-width: 1px;
  border-style: solid;
  /* padding: 0px; */
}

.dropdown-middledot {
  color: #3691ae;
  padding-right: 5px;
}
.dropdown-item:active {
  color: inherit !important;
  text-decoration: none !important;
  background-color: transparent !important;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

@media (min-width: 768px) {
  .navbar-mobile {
    /* display: none; */
  }
  .navbar-collapse-blinder {
    display: none;
  }
  .navbar-collapse-contents {
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .navbar-desktop {
    /* display: none; */
  }
  .sticky-top {
  }
  .navbar-background-color {
  }
  .navbar-background-color div {
  }
  .navbar-responsive {
    position: "absolute" !important;
    width: 50vw;
    height: 100vh !important;
    display: block;
    background-color: rgba(209, 209, 209, 0.5) !important;
  }
  .navbar {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .navbar-collapse {
    position: absolute;
    background-color: transparent !important;
    right: 0;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    width: 100%;
  }
  .navbar-collapse.collapsing {
    height: auto;
    -webkit-transition: right 0.3s ease;
    -o-transition: right 0.3s ease;
    transition: right 0.3s ease;
    right: -100%;
  }
  .navbar-collapse.show {
    right: 0;
    -webkit-transition: right 0.3s ease-in;
    -o-transition: right 0.3s ease-in;
    transition: right 0.3s ease-in;
  }

  .navbar-collapse-blinder {
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.7) !important;
    top: 0px;
    left: 0px;
    width: 20%;
    height: 100vh;
  }
  .navbar-collapse-contents {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.9);
    float: right;
    width: 80%;
    height: 100%;
  }
  .navbar-collapse-contents > div {
    display: inline-block;
  }
  .navbar-collapse-contents > span {
    display: none;
  }
  .dropdown {
    height: 50px;
    padding-top: 12px;
    padding-bottom: 10px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.9);
    border-width: 0px;
    border-bottom-width: 1px;
  }
  .dropdown-menu {
    position: relative !important;
    top: 17px;
    border-width: 0px;
    background-color: rgba(50, 150, 200, 0.2);
    /* position: sticky !important; */
  }

  .dropdown-item {
    padding-top: 8px;
    padding-bottom: 8px;
    height: 40px;
    border-color: rgba(0, 0, 0, 0.15);
    border-width: 0px;
    border-bottom-width: 1px;
    border-style: solid;
    font-size: 0.9rem;
    /* padding: 0px; */
  }
  .nav-item {
    font-size: 0.9rem;
  }
  .mobile-nav-double > a {
    width: 50%;
    height: 40px;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    /* justify-content: center; */
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.9rem;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .mobile-nav-double > a:nth-child(2n-1) {
    /* border-right: 1px solid; */
    /* border-color: rgba(0, 0, 0, 0.15); */
  }
  .navbar-login-button-container {
    width: 100% !important;
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-login-button {
    width: 100%;
    height: 100%;
  }
  .navbar-login-button a {
    width: 100%;
    height: 100%;
    display: block;
  }
}
