.board-contents-title-th {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.board-contents-title-text {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Noto Sans KR";
}

.board-contents-head-text {
  font-size: 1rem;
  font-weight: 400;
  font-family: "Noto Sans KR";
}

.board-contents-head-th-1 {
  width: 20%;
  border-right-width: 1px;
  border-color: black;
}

.board-contents-head-th-2 {
  width: 30%;
}

.board-contents-head-th-3 {
  width: 20%;
}

.board-contents-head-th-4 {
  width: 30%;
}

.board-contents-textarea {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  font-family: "Noto Sans KR";
  font-weight: 400;
  color: black;
  font-size: 1rem;
  word-break: break-all;
}

.board-contents-input {
  padding: 0 !important;
}
.board-contents-input-text {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  font-family: "Noto Sans KR";
}

.board-contents-button {
  background-color: white;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  min-width: 80px;
  height: 40px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 1rem;
}

.board-contents-back-title {
  font-size: 1.5rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .board-contents-image-forIE {
    width: 100%;
  }
}
