.board-button {
  background-color: white;
  height: 40px;
  border-color: black;
  border-width: 1px;
}

.board-button:hover {
  background-color: #5f5f5f;
  color: white;
  height: 40px;
}

.board-select {
  border-radius: 0px;
  height: 40px;
  padding: 10px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  text-align-last: center;
  -webkit-appearance: none; /* 화살표 없애기 for chrome*/
  -moz-appearance: none; /* 화살표 없애기 for firefox*/
  appearance: none; /* 화살표 없애기 공통*/
}

.board-input-box {
  padding: 10px;
  width: 150px;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

a {
  color: inherit;
}

.board-index-button {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: black;
}

.board-controller-button-background {
  background-color: #1782c0;
  border-width: 0;
  color: white;
}

.board-controller-button-background:hover {
  background-color: #1782c0;
  border-width: 0;
  color: white;
}

.board-index-button:hover {
  background-color: black;
  color: white;
}

.board-th-1 {
  width: 10%;
  background-color: #f7f7f7;
}

.board-th-2 {
  max-width: 35%;
  background-color: #f7f7f7;
}

.board-th-3 {
  width: 15%;
  background-color: #f7f7f7;
}

.board-th-4 {
  width: 15%;
  background-color: #f7f7f7;
}

.board-th-5 {
  width: 15%;
  background-color: #f7f7f7;
}

.board-th-6 {
  width: 10%;
  background-color: #f7f7f7;
}

.board-reply-img {
  max-height: 20px;
  width: auto;
}

.board-title-td {
  max-width: 200px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.review-contents-container {
  border-radius: 0 !important;
}

.review-contents-container {
  padding-top: 10px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.review-contents-container:nth-child(5n-4) {
  padding-right: 5px !important;
  padding-top: 10px !important;
}

.review-contents-container:nth-child(5n) {
  padding-top: 10px !important;
  padding-left: 5px !important;
}

.review-contents-image {
  -o-object-fit: cover;
  object-fit: cover;
  width: auto;
  height: 100%;
}

.review-contents-image-horizontal {
  width: 100%;
  height: auto;
}

.review-contents-title-container {
  background-color: #1782c0;
  color: white;
  max-height: 50px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.review-contents-container {
  width: 20%;
}
.review-contents-image-container {
  height: 200px;
}

@media (max-width: 768px) {
  .review-contents-container {
    width: 50%;
  }

  .review-contents-image-container {
    height: 150px;
  }
  .board-th-2 {
    width: 70%;
  }
  .board-th-4 {
    width: 30%;
  }
}
