.login-button {
  font-size: 1.25rem;
  border-width: 0px;
  background-color: transparent;
}

.login-button-left-icon {
  height: 2rem;
}

.login-text-title {
  font-size: 2rem;
}

.login-text-context {
  font-size: 1.4rem;
}

@media (max-width: 480px) {
  .login-text-title {
    font-size: 1.5rem;
  }

  .login-text-context {
    font-size: 1rem;
  }

  .login-text-container {
    margin: 0px;
  }
  .login-arrow-icon {
    width: 25px;
    height: 25px;
  }

  .login-arrow-text {
    font-size: 0.8rem;
  }

  .button-container {
    padding: 0px !important;
    border-width: 0px !important;
  }
}

.button-container {
  border-style: solid;
  border-width: 8px;
  border-color: #eee;
}

.login-arrow-icon {
  width: 25px;
  height: 25px;
}

.login-arrow-text {
  font-size: 1.8rem;
}
