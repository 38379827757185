.breadcrumb-container {
  height: 50px;
  background-color: #f3fafd;
}

.breadcrumb-home {
  font-size: 0.9rem;
  width: 60px;
  height: 50px;
}

.breadcrumb-image {
}

.breadcrumb-first {
  font-size: 0.9rem;
}

.breadcrumb-second {
  font-size: 0.9rem;
}

.breadcrumb-dropdown a {
  height: 50px;
  display: block;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
}

.breadcrumb-second {
  position: relative;
  display: inline-block;
}

.breadcrumb-dropdown {
  display: block;
  position: absolute;
  top: 40px;
  background-color: #f9f9f9;
  min-width: 160px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

@media (max-width: 480px) {
  /* .breadcrumb-home {
    display: none !important;
  } */
  /* .breadcrumb-first {
    display: none !important;
  } */
}

@media (max-width: 768px) {
  /* .breadcrumb-home {
    display: none !important;
  } */
  /* .breadcrumb-first {
    display: none !important;
  } */
  .breadcrumb-responseive-container {
    width: 100%;
  }
  .breadcrumb-first {
    font-size: 0.8rem;
  }

  .breadcrumb-second {
    font-size: 0.8rem;
  }
}
