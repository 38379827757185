.department-container {
  width: 60vw;
  display: inline-block;
}

@media (max-width: 1200px) {
  .department-container {
    width: 100vw;
    height: auto;
    display: inline-block;
  }
}

@media (max-width: 768px) {
  .home-department {
    display: none;
  }
}
