#header-container {
  -ms-flex-pack: "center";
  justify-content: "center";
  -ms-flex-align: "center";
  align-items: "center";
  margin-bottom: 20px;
}

#header-container img {
  width: 100%;
  height: auto;
}

#department-subject-image-container {
  display: inline-block;
}

#line {
  height: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: #f6f6f6;
  /* border-color: black;  */
}

.header-title-left {
  font-family: "Nanum Myeongjo";
  font-size: 3em;
  color: #313131;
}

.header-title-right {
  font-family: "Nanum Myeongjo";
  font-size: 3em;
  font-weight: bold;
  color: #313131;
}

.header-subtitle {
  font-family: "Noto Sans KR";
  color: #6b6b6b;
  font-size: 2em;
  line-height: 1em;
}

#card-1 {
}

#image-container {
  padding: 5px;
}

#title-text {
  font-family: "Noto Sans KR";
  font-weight: bold;
  color: #2d2d2d;
  font-size: 2em;
}

#subtitle-text {
  font-family: "Nanum Gothic";
  font-size: 1em;
  color: #2d2d2d;
}

#department-title-text {
  font-family: "Noto Sans KR";
  font-weight: bold;
  color: #2d2d2d;
  font-size: 2em;
}

#department-subtitle-text {
  font-family: "Nanum Gothic";
  font-size: 1.3em;
  color: #2d2d2d;
}

@media (max-width: 576px) {
  #title-text {
  }

  #department-subtitle-text {
    display: none;
  }
}
