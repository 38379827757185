.board-editor-select {
  border-radius: 0px;
  /* width: 80px; */
  height: 40px;
  padding: 10px;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  -webkit-appearance: none; /* 화살표 없애기 for chrome*/
  -moz-appearance: none; /* 화살표 없애기 for firefox*/
  appearance: none; /* 화살표 없애기 공통*/
}

.board-editor-input {
  border-radius: 0px;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  font-size: 0.9rem;
  font-weight: 400;
  color: black !important;
}

.board-editor-form tr td:nth-child(1) {
  width: 30%;
  vertical-align: middle;
}

.board-editor-form tr td:nth-child(2) {
  width: 70%;
  vertical-align: middle;
}

.board-editor-form-submit-button {
  width: 100px;
  height: 40px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  background-color: white !important;
}

.board-editor-phonenumber {
  width: 25%;
  display: inline;
}

.contents-editor-agreement-text-box {
  height: 300px;
  overflow: auto;
}
