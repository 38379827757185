body {
  font-family: "Noto Sans KR", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 12px;
}

.row {
  margin-left: auto !important;
  margin-right: auto !important;
}

#fee {
 width: 60vw;
 margin-left: auto;
 margin-right: auto;

 text-align: right;

 @media (max-width: 1200px) {
    width: 100%;
  }

  img {
    width: 200px;
    height: auto;
  }
}