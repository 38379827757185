.homecard-container {
  width: 60vw;
  height: auto;
}

.homecard-column-container {
}

.homecard-inner-container {
}

.home-card-vertical-align {
  vertical-align: middle;
}

.home-card-vertical-align-bottom {
  vertical-align: bottom;
}

@media (max-width: 1300px) {
  .homecard-container {
    width: 100vw;
    height: auto;
  }
}

@media (max-width: 576px) {
  .homecard-column-container {
    margin: 15px 0px 15px 0px;
  }
  .homecard-container {
    text-align: center;
  }
}

.carousel-indicators {
  margin-left: 60%;
  margin-right: 0%;
  left: 10%;
}

.carousel-indicators li {
  background-color: white;
  width: 10px !important;
  height: 10px !important;
}

.carousel-indicators .active {
  background-color: #999;
  width: 10px !important;
  height: 10px !important;
}
